import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './components/main/main.component';
import { LoginComponent } from './components/login/login.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HeaderComponent } from './components/header/header.component';
import { TranslatePipe } from './pipes/translate.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { NumberPipe } from './pipes/number.pipe';
import { TranslateService } from './services/translate.service';
import { NgxWebstorageModule} from 'ngx-webstorage';
import { LichnoDosieComponent } from './components/lichno-dosie/lichno-dosie.component';
import { KupuvanjeUdeliComponent } from './components/kupuvanje-udeli/kupuvanje-udeli.component';
import { PagerService } from './services/pager.service';
import { PagerComponent } from './components/pager/pager.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { OtkupUdeliComponent } from './components/otkup-udeli/otkup-udeli.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { PristapnicaComponent } from './components/pristapnica/pristapnica.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    SidebarComponent,
    HeaderComponent,
    TranslatePipe,
    SearchPipe,
    NumberPipe,
    LichnoDosieComponent,
    KupuvanjeUdeliComponent,
    PagerComponent,
    OtkupUdeliComponent,
    DocumentsComponent,
    PristapnicaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgxWebstorageModule.forRoot(),
    ReactiveFormsModule,
    NgxDaterangepickerMd.forRoot(),
  ],
  providers: [TranslateService,
              NumberPipe,
              PagerService
             ],
  bootstrap: [AppComponent]
})
export class AppModule { }
