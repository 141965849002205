import { Component, OnInit, Input } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  
  @Input() setClassAside: boolean;
  constructor() { }

  ngOnInit() {
  }

  
  openableSideMenu(){
    $("#liOpen").each(function () {
      $(this).toggleClass("open");
    });
  }

  ngOnChanges(...args: any[]){
    this.showHide(); 
  }

  showHide(){
    if(this.setClassAside == true){
      $("#page-aside").each(function () {
        $(this).addClass("page-aside--minimized");
      });
    }else{
      $("#page-aside").each(function () {
        $(this).removeClass("page-aside--minimized");
      });
    }
  }

}
