import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-lichno-dosie',
  templateUrl: './lichno-dosie.component.html',
  styleUrls: ['./lichno-dosie.component.css']
})
export class LichnoDosieComponent implements OnInit {

  constructor() { }
  formaFizicko:boolean = false;
  formaPravno:boolean = false;

  ngOnInit() {
    this.Buttons();
  }


formFizicko(){
  this.formaFizicko = true;
  this.formaPravno = false;
}
formPravno(){
  this.formaFizicko = false;
  this.formaPravno = true;
}

  Buttons() {
    $("[data-demo-action='update']").each(function () {

      var card = $(this).parents(".card");

      $(this).click(function (e) {

        e.preventDefault();

        // app._loading.show(card,{spinner: true});                

        setTimeout(function () {
          // app._loading.hide(card);
        }, 2000);

        return false;
      });
    });
    $("[data-demo-action='expand']").each(function () {

      var card = $(this).parents(".card");

      $(this).click(function (e) {

        e.preventDefault();

        // app._loading.show(card,{spinner: true});

        $(this).toggleClass("active");
        card.toggleClass("card--expanded");

        // app._crt();

        setTimeout(function () {
          // app._loading.hide(card);
        }, 1000);

        return false;
      });
    });

    // end expand card button

    // invert card button                
    $("[data-demo-action='invert']").each(function () {

      var card = $(this).parents(".card");

      if (card.hasClass("invert")) {
        $(this).addClass("active");
      }

      $(this).click(function (e) {
        e.preventDefault();

        $(this).toggleClass("active");
        card.toggleClass("invert");

        return false;
      });
    });
    $("button.transform1").click(function () {
      $("span.rotate1").toggleClass("down");
    });
    $("button.transform2").click(function () {
      $("span.rotate2").toggleClass("down");
    });
    $(".transform3").click(function () {
      $("span.rotate3").toggleClass("down");
    });
    $(".transform4").click(function () {
      $("span.rotate4").toggleClass("down");
    });
  }

}
