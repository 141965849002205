import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  user: FormGroup;

  constructor(private router: Router,
    private fb: FormBuilder) {


    this.user = fb.group({
      'username': [''],
      'password': ['']
    });


  }

  ngOnInit() {
  }

  Login() {
    sessionStorage.setItem('username',this.user.controls['username'].value);
    sessionStorage.setItem('password',this.user.controls['password'].value);
    this.router.navigate(['/main/lichnoDosie']);
  }

}
