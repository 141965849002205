import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { MainComponent } from './components/main/main.component';
import { LichnoDosieComponent } from './components/lichno-dosie/lichno-dosie.component';
import { KupuvanjeUdeliComponent } from './components/kupuvanje-udeli/kupuvanje-udeli.component';
import { OtkupUdeliComponent } from './components/otkup-udeli/otkup-udeli.component';
import { PristapnicaComponent } from './components/pristapnica/pristapnica.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'main', 
    component: MainComponent,
    children:[
      { path: 'lichnoDosie', component: LichnoDosieComponent },
      { path: 'pristapnica', component: PristapnicaComponent },
      { path: 'baranjeZaKupuvanjeUdeli', component: KupuvanjeUdeliComponent },
      { path: 'baranjeZaOtkupUdeli', component: OtkupUdeliComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
