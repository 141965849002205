export const LANG_MK_NAME = 'mk';

export const LANG_MK_TRANS = {
    'logo': 'КБ ПУБЛИКУМ ИНВЕСТ АД',
    'username': 'Корисничко име',
    'password': 'Лозинка',
    'login': 'Најава',
    'pochetna': 'Почетна',
    'odjavise': 'Одјави се',
    'promenaNaLozinka': 'Промена на лозинка',
    'mojProfil': 'Мој профил',
    'baranjeKupuvanjeUdeli': 'Барање за купување на удели',
    'fond': 'Фонд',
    'period': 'Период',
    'baraj': 'Барај',
    'brBaranje': 'Број на барање',
    'imePrezime': 'Име и презиме',
    'datum': 'Датум',
    'telefon': 'Телефон',
    'email': 'Е-пошта',
    'ednokratnaUplata': 'Еднократна уплата',
    'programiranaUplata': 'Програмирана уплата',
    'embg': 'ЕМБГ',
    'adresa': 'Адреса',
    'banka': 'Банка',
    'mobilen': 'Мобилен',
    'brojNaTransakciskaSmetka': 'Трансакциска сметка/IBAN',
    'brLichnaKarta/pasosh': 'Бр. на л.к./пасош',
    'vaznostLichnaKarta/pasosh': 'Важност на лична карта/пасош',
    'brojNaPristapnica': 'Број на пристапница',
    'investitor': 'Инвеститор',
    'podatociInvestitor': 'Податоци за инвеститорот',
    'fizichko': 'Физичко лице',
    'pravno': 'Правно лице',
    'rezident': 'Резидент',
    'nerezident': 'Нерезидент',
    'imeprezime/naziv': 'Име и Презиме/Назив',
    'imeprezimeOdgovornolice': 'Име и Презиме на одговорно лице',
    'adresaSedishte': 'Адреса/Седиште',
    'poshtenskiBroj/grad/drzava': 'Поштенски број/град/држава',
    'swift': 'SWIFT(нерезиденти)',
    'ovlastenPomoshnik': 'Овластен полномошник',
    'zakonskiZastapnik/ovlastenolice': '',
    'vaziDo': 'Важи до',
    'izdadenaOd': 'Издадена од',
    'zakonskiZastapnik': 'Законски застапник',
    'ovlastenoLice': 'Овластено лице',
    'ovlastenPredstavnik': 'Овластен претставник на друштвото',
    'mestoNaPodnesuvanje': 'Место на поднесување',
    'datumVreme': 'Датум и време',
    'nachinDobivanjePotvrda': 'Начин на добивање на потврда',
    'poshta': 'Пошта',
    'faks': 'Факс',
    'voDrushtvoto': 'Во друштвото',
    'vidUplata': 'Вид на уплата',
    'ednokratna': 'Еднократна уплата',
    'programirana': 'Програмирана уплата',
    'iznos': 'Износ',
    'voPeriod': 'Во период',
    'dokumenti': 'Документи',
    'kupuvanjeUdeli': 'Купување на удели',
    'edb': 'ЕДБ',
    'mb': 'Матичен број',
    'snimi': 'Сними',
    'pechati': 'Печати',
    'pratiEmail': 'Прати по е-пошта',
    'snimiPrimera': 'Сними во Примера',
    'baranjeOtkupUdeli': 'Барање за откуп на удели',
    'otkupUdeli': 'Откуп на удели',
    'brUdeli': 'Број на удели',
    'nazivBanka': 'Назив на банка',
    'brSmetka': 'Број на сметка',
    'DatumPrikacuvanje': 'Датум на прикачување',
    'PrikacenoOd': 'Прикачено од',
    'TipDoc': 'Тип на документ',
    'Zabeleshka': 'Забелешка',
    'Prikachi': 'Прикачи',
    'brojNaDoc': 'Број на документ',
    'ValidenDo': 'Валиден до',
    'PrikachiDoc': 'Прикачи документ',
    'pristapnica': 'Пристапница',
    'pristapnicaFond': 'Пристапница во отворен инвестициски фонд',
    'nacinInformiranje': 'Начин на информирање',
    'nova': 'Нова',
    'novo': 'Новo',
    'licniPodatoci': 'Лични Податоци / Personal Data',
    'embg/danocen': 'ЕМБГ/Даночен број',
    'KlientID': 'КлиентID',
    'novoFizicko': 'Ново Физичко',
    'novoPravno': 'Ново Правно',
    'fizicko/pravno': 'Физичко/Правно',
    'licnoDosie': 'Лично Досие',
    'imePrezime/NameSurname': 'Име и презиме / Name and Surname',
    'tatkovoIme/FathersName': 'Татково име / Father`s name',
    'EMBG/ Citizen`s Personal Number': 'ЕМБГ / Citizen`s Personal number',
    'datumRaganje': 'Датум и место на раѓање / Date and place of birth',
    'drzavaMestoRaganje': 'Држава и место на раѓање / Country and place of birth',
    'adresaZiveenje': 'Адреса на живеење / Residence address',
    'identifikacija/identification': 'Идентификација / Identification',
    'licnaKarta/IdCard': 'Лична карта број / ID card no.',
    'organIzdavanje': 'Орган на издавање / Issued by',
    'datumVaznost': 'Датум на важност / Valid through',
    'drzava': 'Држава / Country',
    'pasos': 'Пасош број / Passport no.',
    'podatociKlient': 'Податоци за контакт на клиентот / Client`s Contact Data',
    'adresaKontakt': 'Адреса за контакт / Contact adress',
    'status': 'Статус/професионална ангажираност / Status/professional engagement',
    'dete/child': 'Дете / Child',
    'student': 'Студент / Student',
    'penzioner': 'Пензионер / Retired',
    'nevraboten': 'Невработен / Unemployed',
    'vraboten': 'Вработен / Employed',
    'rabotnik': 'Работник / Worker',
    'sudija': 'Судија / Judge',
    'sluzbenik': 'Службеник / Public officer',
    'doktor': 'Доктор / Doctor',
    'manager': 'Менаџер / Manager',
    'advokat': 'Адвокат / Lawyer',
    'profesor': 'Професор / Professor',
    'samostoenVrsitel': 'Самостоен вршител на дејност / Self-employed',
    'notar': 'Нотар / Notary public',
    'izvrsitel': 'Извршител / Enforcement agent',
    'drugo': 'Друго / Other',
    'rabotodavec': 'Работодавец / Employer',
    'adresaRabotodavec': 'Адреса на работодавец / Employer`s address',
    'dejnostRabotodavec': 'Дејност на работодавец / Employer`s business activity',
    'industrija': 'Индустрија и градежништво / Construction and industry',
    'trgovija': 'Трговија / Trade',
    'turizam': 'Туризам и угостителство / Tourism and hospitality',
    'finansiskoPosrednistvo': 'Финансиско посредништво и банки / Financial intermediary and banks',
    'obrazovanie': 'Образование / Education',
    'zdravstvo': 'Здравство / Health',
    'smetkovodstvo': 'Сметководство, информатика и телекомуникации / accounting, IT or telecommunication',
    'soobrakaj': 'Сообраќај / Traffic',
    'javnaUprava': 'Јавна управа (администрација) / Public administration',
    'sportKultura': 'Спорт, уметност и култура / Sport, art or culture',
    'zemjodelelstvo': 'Земјоделие / Agriculture',
    'advokati': 'Адвокати / Lawyers',
    'notari': 'Нотари / Notary public',
    'izvrsiteli': 'Извршители / Enforcement agent',
    'ekonomskoKonsalting': 'Економско-правен Консалтинг / Economic and legal consultancy',
    'nevladiniOrganizacii': 'Невладини организации / NGO',
    'megunarodniOrganizacii': 'Меѓународни организации / International organizations',
    'kazina': 'Казина, спортски обложувалници, игри на среќа / Casinos, sport betting and other betting games',
    'drugiPodatoci': 'Други податоци за клиентот / Other information for the client',
    'drzavaPrestoj': 'Држава на престој / Country of residence',
    'drzavjanstvo': 'Државјанство / Citizenship',
    'redovnoPrimanje': 'Износ на редовно месечно / Regular monthly income in the amount of',
    'visinaPrimanja': 'Висина на месечни примања / Average monthly income',
    'do20000den': 'до 20.000,00 денари / up to 20.000,00 MKD',
    'od20000do50000': 'од 20.000,00 денари до 50.000,00 / from 20.000,00 to 50.000,00 MKD ',
    'nad50000': 'над 50.000,00 денари / over 50.000,00 MKD',
    'neostvaruvaPrimanja': 'Не остварува редовни месечни примања / Does not have regular monthly income',
    'dopolnitelniIzvori': 'Други дополнителни извори на средства / Other additional income sources',
    'yes': 'да / yes',
    'no': 'не / no',
    'do30000': 'до 30.0000,00 денари / up to 30.000,00 MKD',
    'nad30000': 'над 30.000,00 денари / over 30.000,00 MKD',
    'imotRaspolaga': 'Имот со кој располага / Assets at client`s disposal',
    'stan': 'Стан, куќа / Appartment, house',
    'realestate': 'Недвижност / Real estate',
    'sopstvenoPravno': 'Сопствено правно / Own legal entity',
    'udelAkciiPravno': 'Удел/ Акции правно (над 25% од сопстеноста) / Shares/ Stocks in legal entity (over 25% ownership)',
    'drugImot': 'Друг имот/ other',
    'neRaspolagaImot': 'Не располага со имот / Does not have any property',
    'bracnaSostojba': 'Брачна состојба / Civil status',
    'imeBracenDrugar': 'Име на брачен другар / Name of the spouse',
    'single': 'Нежент / немажена / Single',
    'transakciskiSmetki': 'Трансакциски сметки во банка/ Bank account',
    'imeBanka': 'Име на банка/ Name of bank',
    'izjavaZaPostoenje': 'Изјава за постоење на својство носител на јавна функција на клиентот / Statement regarding the client`s capacity as a public official',
    'nositel': 'Не сум носител на јавна функција / Am not a public official',
    'nenositel': 'Сум носител на јавна функција / Am a public official',
    'osnovniPodatoci': 'Основни податоци / General Data',
    'polnNaziv': 'Полн назив / Entire name',
    'skratenNaziv': 'Скратен назив / Short name',
    'danocenbroj': 'Даночен број / Tax no.',
    'embs': 'Матичен број (ЕМБС) / Company no. (EMBS)',
    'grad': 'Град / City',
    'mail': 'Е-маил / E-mail',
    'url': 'Веб страна / URL',
    'registracija': 'Регистрација / Registration',
    'datumUpis': 'Датум на упис / Date of entry',
    'dopolnitelnoResenie': 'Дополнително решение/согласност/одобрение за вршење на дејноста / Additional resolution/permit/approval for business activity',
    'organizaciskiOblik': 'Организациски облик / Organizational structure',
    'goleminaPravnoLice': 'Големина на правното лице согласно Закон за трговски друштва или соодветен / Size of legal entity, according to the Law on trade companies or relevant law',
    'brVraboteni': 'Број на вработени / Number of employees',
    'dejnostPravnoLice': 'Дејност на правното лице / Business activity',
    'zdruzenieGragani': 'Здружение на граѓани / Associations',
    'javnaFunkcijaPravo': 'Изјава за носител на јавна функција / Statemnet of a public official',
    'pecati': 'Печати',
    'osnovaciPravno': 'Основачи/сопственици на правното лице / Owners/ founders of the legal entity',
    'citizenID': 'ЕМБГ / Citizen`s ID no',
    'licnakartaPasos': 'Лична карта/пасош / ID card / passport',
    'kapitalnaDobivkaKratokRok': 'Остварување на капитална добивка на краток рок/ capital gains in the short term',
    'kapitalnaDobivkaPodolgRok': 'Остварување на капитална добивка на подолг рок/ capital gains in the long term',
    'voBrak': 'Во брак / Married',
    'smetki': 'Сметки/Bank Accounts',
    'brSmetka/IBAN': 'Трансакциска сметка/IBAN',
    'imenaBanka': 'Име на банка/Name of the bank',
    'otvorenaNa': 'Отворена на/Opened on',
    'zatvorenaNa': 'Затворена на/Closed on',
    'statusSmetka': 'Статус/Status',
    'zabeleska': 'Забелешка/Note',


}