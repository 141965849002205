import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationEnd } from '@angular/router';
import { RoutesRecognized } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  urlList: any;
  title: any;
  flag: boolean;
  @Output() hideSidebarBlack = new EventEmitter();
  @Output() hideSidebarWhite = new EventEmitter();
  flagSidebarWhite: boolean;

  constructor(private router: Router) {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.urlList = event;    
        if(this.urlList.url == "/main/lichnoDosie"){
          this.title = "Лично досие";
        }else if(this.urlList.url == "/main/pristapnica"){
          this.title = "Пристапница";
        }else if(this.urlList.url == "/main/baranjeZaKupuvanjeUdeli"){
          this.title = "Барање за купување на удели";
        }else if(this.urlList.url == "/main/baranjeZaOtkupUdeli"){
          this.title = "Барање за откуп на удели";
        }
      });
  }

  ngOnInit() {
    $(".imgFlags").select2({
      templateResult: this.formatState,
      templateSelection: this.formatState,
      minimumResultsForSearch: Infinity
    });
  }
  sidebarWhiteHide() {
    this.flagSidebarWhite = !this.flagSidebarWhite;
    this.hideSidebarWhite.emit(this.flagSidebarWhite);
    $("#page-sidepanel").toggleClass("page-sidepanel--hidden");
    this.flag=true;
    this.hideSidebarBlack.emit(this.flag);
}
openSandace() {

  this.flagSidebarWhite = true;
  this.hideSidebarWhite.emit(this.flagSidebarWhite);
  $("#page-sidepanel").removeClass("page-sidepanel--hidden");
  this.flag=true;
  this.hideSidebarBlack.emit(this.flag);
}
  clickBtn(){
    this.flag = !this.flag;
    this.hideSidebarBlack.emit(this.flag);
    
  }

  formatState (state) {
    if (!state.id) { return state.text; }
    var $state = $(
      '<span style="line-height: 28px;"><img src="assets/img/Flags/' +  state.element.value.toLowerCase() + 
  '.png" class="img-flag" /> ' +  state.text +     '</span>'
   );
   return $state;
  };



}
