import { Component, OnInit } from '@angular/core';
declare var $: any;
import * as moment from 'moment';
import { Moment } from 'moment';
@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {

  selectedAll: any;
  allDocuments = [
    { datumPrikachuvanje: '20.06.2018', prikachenoOd: 'Моника Петровска', tipDoc: 'копија л.к.', zabeleshka: 'нема', brDoc: '20-123/4', validenDo: '12.07.2020', selected: false },
    { datumPrikachuvanje: '20.07.2018', prikachenoOd: 'Драга Ставреска', tipDoc: 'копија л.к.', zabeleshka: 'нема', brDoc: '20-123/4', validenDo: '12.07.2020', selected: false },
    { datumPrikachuvanje: '20.03.2018', prikachenoOd: 'Дино Ангеловски', tipDoc: 'копија л.к.', zabeleshka: 'нема', brDoc: '20-123/4', validenDo: '12.07.2020', selected: false },
    { datumPrikachuvanje: '20.01.2018', prikachenoOd: 'Ивица Томески', tipDoc: 'копија л.к.', zabeleshka: 'нема', brDoc: '20-123/4', validenDo: '12.07.2020', selected: false },
  ];
  constructor() { }

  ngOnInit() {
    this.Buttons();
  }


  selectAll() {
    for (var i = 0; i < this.allDocuments.length; i++) {
      this.allDocuments[i].selected = this.selectedAll;
    }
  }

  checkIfAllSelected() {
    this.selectedAll = this.allDocuments.every(function (item: any) {
      return item.selected == true;
    })
  }



  Buttons() {
    $("[data-demo-action='update']").each(function () {

      var card = $(this).parents(".card");

      $(this).click(function (e) {

        e.preventDefault();

        // app._loading.show(card,{spinner: true});                

        setTimeout(function () {
          // app._loading.hide(card);
        }, 2000);

        return false;
      });
    });
    $("[data-demo-action='expand']").each(function () {

      var card = $(this).parents(".card");

      $(this).click(function (e) {

        e.preventDefault();

        // app._loading.show(card,{spinner: true});

        $(this).toggleClass("active");
        card.toggleClass("card--expanded");

        // app._crt();

        setTimeout(function () {
          // app._loading.hide(card);
        }, 1000);

        return false;
      });
    });

    // end expand card button

    // invert card button                
    $("[data-demo-action='invert']").each(function () {

      var card = $(this).parents(".card");

      if (card.hasClass("invert")) {
        $(this).addClass("active");
      }

      $(this).click(function (e) {
        e.preventDefault();

        $(this).toggleClass("active");
        card.toggleClass("invert");

        return false;
      });
    });
    $("button.transform1").click(function () {
      $("span.rotate1").toggleClass("down");
    });
    $("button.transform2").click(function () {
      $("span.rotate2").toggleClass("down");
    });
    $(".transform3").click(function () {
      $("span.rotate3").toggleClass("down");
    });
    $(".transform4").click(function () {
      $("span.rotate4").toggleClass("down");
    });
  }

}
