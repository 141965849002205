import { Component, OnInit } from '@angular/core';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Input } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  checkAsideFlag: boolean;
  flagSidebarWhite: boolean;
  constructor() { }

  ngOnInit() {
  }

  check(event){
    this.checkAsideFlag = event;
    if(this.checkAsideFlag == true){
      $("#page-content").each(function () {
        $(this).addClass("page-aside--minimized");
      });
    }else{
      $("#page-content").each(function () {
        $(this).removeClass("page-aside--minimized");
      });
    }
  }
  check2(event){
    this.flagSidebarWhite = event;
    
    if(this.flagSidebarWhite == true){
      $("#page-content").each(function () {
        $(this).addClass("page-sidepanel--hidden");
      });
    }else{
      

      $("#page-content").each(function () {
        $(this).removeClass("page-sidepanel--hidden");
      });
    }
  }

  

}
